<template>
  <v-card :color="`grey lighten-${bracket ? 4 : 2}`" class="fill-height">
    <div v-if="false">{{ lineupLock }}</div>
    <v-card-text v-if="bracket" class="pb-0 d-flex justify-space-between">
      <span>
        <v-btn
          color="color3 color3Text--text"
          small fab
          @click="backClick"
        >
          <v-icon>fas fa-caret-left</v-icon>
        </v-btn>
        <v-btn color="color3" text @click="backClick">Back to bracket</v-btn>
      </span>
      <match-print
        v-if="tournament.isNcaa && view.adminAndRefs"
        :tournament="tournament"
        :division="division"
        :round="round"
        :matches="matches"
        :pool="null"
        :block="false"
        :text="true"
        :xSmall="false"
      ></match-print>
    </v-card-text>
    <v-card-title v-if="!noTitle" class="pb-0">
      {{dualText}} {{dual.displayN}}
      <v-chip outlined :color="dual.complete ? 'black' : 'success'" small width="100%" v-if="dualStatus" class="ml-2">
        <v-icon small class="mr-2" v-if="!dual.complete">fas fa-signal-stream</v-icon> {{dualStatus}}
      </v-chip>
      <v-spacer></v-spacer>
      <lineup-assign
        v-if="!locked && !displayOnly"
        :teamIds="teamIds"
        :matches="matches"
        :division="division"
        :round="round"
        :name="`${round.name} ${dualText} ${dual.displayN}`"
        :lineupLock="lineupLock"
      ></lineup-assign>
    </v-card-title>
    <v-card-text :class="{'px-1': $vuetify.breakpoint.xsOnly}">
      <v-row dense class="justify-end" v-if="noTitle">
        <lineup-assign
          v-if="!locked && !displayOnly"
          :teamIds="teamIds"
          :matches="matches"
          :division="division"
          :round="round"
          :name="`${round.name} ${dualText} ${dual.displayN}`"
          :lineupLock="lineupLock"
        ></lineup-assign>
      </v-row>
      <v-row dense class="pa-0">
        <v-col :cols="cols || 12" class="pa-0 d-flex justify-space-between">
          <v-btn
            v-if="!displayOnly"
            color="color3"
            x-small text
            @click.stop="mini = !mini"
          >
            {{mini ? 'expand' : 'minify'}}
          </v-btn>
          <dual-court-assignment
            v-if="view.adminAndRefs && !displayOnly"
            :matches="dual.matches"
            :name="`Dual ${dual.displayN}`"
          ></dual-court-assignment>
        </v-col>
      </v-row>
      <v-expand-transition>
        <v-row dense v-if="!mini">
          <v-col :cols="cols || 12" v-for="(match) in dual.matches" :key="`${myKey}_${match.number}_${match.id}`" :offset="colOffset">
            <match-card
              :matchIn="match"
              :pool="pool"
              :teamColors="teamColors"
              :bracketCard="bracketCard"
              :showMap="showMap"
              :bracket="bracket"
              :division="division"
              :round="round"
              @match-saved="onMatchSaved"
              :lineupLock="lineupLock"
              :displayOnly="displayOnly"
            ></match-card>
          </v-col>
        </v-row>
      </v-expand-transition>
      <v-expand-transition>
        <v-row dense v-if="mini">
          <v-col cols="12" v-for="(match) in dual.matches" :key="`${myKey}_${match.number}_${match.id}`" class="pa-0 px-2">
            <bracket-match-card
              :matchIn="match"
              :pool="pool"
              :teamColors="teamColors"
              :bracketCard="bracketCard"
              :showMap="showMap"
              :bracket="bracket"
              :division="division"
              :round="round"
              @match-saved="onMatchSaved"
              :lowlight="false"
              :nums="true"
              :lineupLock="lineupLock"
            ></bracket-match-card>
          </v-col>
        </v-row>
      </v-expand-transition>
      <slot></slot>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import MatchCard from '@/components/Tournament/Match/FullMatchCard.vue'
import BracketMatchCard from '@/components/Tournament/Match/BracketMatchCard.vue'
import LineupAssign from '@/components/Team/Lineups/LineupAssignDrawer.vue'
import DualCourtAssignment from './DualCourtAssignment.vue'
import moment from 'moment'
const MatchPrint = () => import('@/components/Printing/MatchPrint')

export default {
  props: ['dual', 'pool', 'showMap', 'bracket', 'bracketCard', 'myKey', 'noTitle', 'division', 'round', 'displayOnly', 'cols'],
  data () {
    return {
      mini: false,
      refreshKey: 1,
      timeOut: null
    }
  },
  computed: {
    ...mapGetters(['teamColors', 'tournament', 'view', 'getTeam', 'userTeams']),
    dualText () {
      return this.division.dualText
    },
    colOffset () {
      if (this.cols) {
        return (12 - this.cols) / 2
      }
      return null
    },
    homeScore () {
      return this.dual.homeScore
    },
    matchesNeededForWin () {
      return this.dual.matchesNeededForWin
    },
    teamIds () {
      return this.dual.teamIds
    },
    teams () {
      return this.teamIds && this.teamIds.map(m => this.getTeam(m))
    },
    locked () {
      return this.tournament.locked || this.division.complete || (this.bracketCard && this.bracket.locked) || (!this.bracketCard && this.pool.locked)
    },
    matches () {
      return this.dual.matches
    },
    dualStatus () {
      return this.division.dualText.startsWith('D') && this.dual.getStatus(this.teams)
    },
    lineupLock () {
      return this.refreshKey && this.division.lineupLockObj(this.dual.startTime, this.view, this.userTeams)
    }
  },
  methods: {
    onMatchSaved () {
      this.$emit('match-saved')
    },
    backClick () {
      this.$router.push(this.$route.path)
    },
    checkSetRefresh () {
      if (this.lineupLock.hasLock && this.lineupLock.isLocked) return

      const d = moment(this.lineupLock.dtLock).diff(moment())
      this.timeOut = setTimeout(() => {
        this.refreshKey++
      }, d)
    }
  },
  mounted () {
    this.checkSetRefresh()
  },
  destroyed () {
    if (this.timeOut) clearTimeout(this.timeOut)
  },
  watch: {
    'lineupLock.dtLock': 'checkSetRefresh'
  },
  components: {
    MatchCard,
    LineupAssign,
    DualCourtAssignment,
    BracketMatchCard,
    MatchPrint
  }
}
</script>
